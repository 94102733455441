import React, {useEffect, useState} from 'react';
import bnaFondation from './images/bna_fondation.png';
import nouraneLogo from './images/nourane_logo.png';
import bnaLogo from './images/bna_logo.png';
import './App.css';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

const center = [35.5, 10.210];
const exSiteUrl = 'https://nouraneapi.cmwh.ovh/';
//const exSiteUrl = 'http://localhost:5000/';
const exImagesUrl = 'https://nouraneapi.cmwh.ovh/static/uploads/';

const App = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [classement, setClassement] = useState([]);
  const [activeRunner, setActiveRunner] = useState([]);
  const [fakeKmItems, setFakeKmItems] = useState([]);
  const [km, setKm] = useState(0);
  const [fakeKm, setFakeKm] = useState(0);
  const [participants, setParticipants] = useState(0);
  const [inscrits, setInscrits] = useState(0);
  const [isFake, setIsFake] = useState(false);


  useEffect(() => {
    getClassment();
    getKm();
    getFakeKm();
    getInscrits();
    getActive();
  }, []);

  useEffect(() => {
    getClassment();
    getKm();
    getFakeKm();
    getInscrits();
    getActive();
  }, []);


  useEffect(() => {
    
      const timer = setInterval(() => {
      getClassment();
      getKm();
      getFakeKm();
      getInscrits();
      getActive();

      }, 30000);
      return () => clearInterval(timer);
   
  }, []);

  const getClassment = () =>
  {
    setIsLoading(false);
    axios.get( exSiteUrl + 'stats/getSortedMarathons')
    .then(response => {
        setClassement(() => { return response.data })
        setIsLoading(() => { return true });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getActive = () =>
  {
    setIsLoading(false);
    axios.get( exSiteUrl + 'stats/getactive')
    .then(response => {
        setActiveRunner(() => { return response.data })
        setIsLoading(() => { return true });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getInscrits = () =>
  {
    setIsLoading(false);
    axios.get( exSiteUrl + 'stats/runners')
    .then(response => {
        setInscrits(() => { return response.data.inscrits })
        setIsLoading(() => { return true });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getKm = () =>
  {
    setIsLoading(false);
    axios.get( exSiteUrl + 'stats/km')
    .then(response => {
        setKm(() => { return response.data.km })
        setParticipants(() => { return response.data.participants })
        console.log(response.data);
        setIsLoading(() => { return true });
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const getFakeKm = () =>
  {
    setIsLoading(false);
    axios.get( exSiteUrl + 'stats/fakekm')
    .then(response => {
        setFakeKm(() => { return response.data.km })
        setFakeKmItems(() => { return response.data.items })
        setIsLoading(() => { return true });
    })
    .catch((error) => {
      console.log(error);
    });
  }

 
  const renderMap = () => {
    return(
        <div className="map">
                <Map center={center} zoom={8}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <TileLayer url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png" />

                    {activeRunner.map((mp, index) => (
                        <Marker position={[mp.final_location.position.lat, mp.final_location.position.lon]} key={`mp-${index+1}`}>
                            <Popup>{mp.runner_data.prenom + ' ' + mp.runner_data.nom}<br />{ 'Distance : ' + mp.final_location.distance.toFixed(2) + ' km'}</Popup>
                        </Marker>
                    ))}

                </Map>
        </div>
    )
  }

  const renderStats = () => {
    return(
      <div className="statZone">
        <div className="statBlock">
          <div className="statLabel">DISTANCE PARCURUE</div>
          <div className="blocKm">{km.toFixed(2)}<span> KM</span></div>
        </div>

        <div className="statBlock">
          <div className="statLabel">INSCRITS</div>
          <div className="blocOver">{inscrits}</div>
        </div>

        <div className="statBlock">
          <div className="statLabel">PARTICIPATIONS</div>
          <div className="blocOver">{participants}</div>
        </div>

        <div className="statBlock">
          <div className="statLabel">EN ACTIVITE MAINTENANT</div>
          <div className="blocOver">{activeRunner.length}</div>
        </div>

        <div className="blackList">
          <div className="statLabel colRed">DISTANCE NON PRISE EN COMPTE</div>
          <div className="blocOver colRed">{fakeKm.toFixed(2)}<span> KM</span></div>
          <div className="statplusDet" onClick={() => setIsFake(true)}>Plus de détails</div>
        </div>

      </div>
    )
  }

  const renderClassement = () =>
  {
    return(
      <div className="classementZone">
        <div className="statBlock">
          <div className="blocOver">CLASSEMENT</div>
        </div>
        <div className="classItems">
          <Scrollbars style={{ width: '100%', height: '100%' }}>
            <div className="clItems">
            { classement.map((cl, index) =>
              <div className="classItem" key={`clas-${index+1}`}>
                <div className="clNumber">{index + 1}</div>
                <div className="clContent">
                    <div className="clContentName">{cl.runner_data.prenom + ' ' + cl.runner_data.nom}</div>
                    <div className="clStats">
                        <div className="clStatsLabel">DISTANCE</div>
                        <div className="clStatsValue">{cl.totaldistance.toFixed(2)} Km</div>
                        <div className="clStatsLabel">DUREE</div>
                        <div className="clStatsValue">{(Number(cl.totalcounter) / 60000 ).toFixed(0)} Min</div>
                    </div>
                    <div className="participNumber">{cl.count} PARTICIPATIONS</div>
                </div>
                
              </div>
            )}
            </div>  
          </Scrollbars>
        </div>
      </div>
    )
  }

  const renderFake = () =>
  {
    return(
      <div className="overley">
        <div className="popUp">
          <div className="popUpHeader">
            <div className="popUpTitle">{fakeKm.toFixed(2)}<span> Km</span></div>
            <div className="popUpsubTitle">{fakeKmItems.length} Participants</div>
            <div className="closePoPup" onClick={() => setIsFake(false)}>X</div>
          </div>
          <div className="popUpContent">
            <Scrollbars style={{ width: '100%', height: '100%' }}>
              <div className="popUpContentItems">
              { fakeKmItems.map((fcl, index) =>
                <div className="popUpContentItem" key={`fcl-${index+1}`}>
                  <div className="itemName">{fcl.runner_data.prenom + ' ' + fcl.runner_data.nom}</div>
                  <div className="itemOver">{fcl.final_location.distance.toFixed(2)} Km</div>
                  <div className="itemOver">{(Number(fcl.final_location.counter) / 60000).toFixed(0)} Min</div>
                  <div className="itemOver alRight" >{ (((Number(fcl.final_location.vitesse) * 60) / 1000) * (60/100)).toFixed(0) + '  Km/h'}</div>
                </div>
              )}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      {renderMap()}
      {renderStats()}
      {renderClassement()}
      <div className="App-header">
        <div className="App-header-logo"><img src={bnaFondation} alt="marathon Nourane"></img></div>
        <div className="App-header-logo"><img src={nouraneLogo} alt="marathon Nourane"></img></div>
        <div className="bnaLogo"><img src={bnaLogo} alt="marathon Nourane"></img></div>
      </div>
      {isFake && renderFake()}
    </div>
  );
}

export default App;
